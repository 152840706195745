import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-button',
  template: `
    <button
      nz-button
      class="button"
      [nzType]="'default'"
      [nzSize]="!small ? 'large' : 'small'"
      [class.button]="!small"
      [class.button-small]="small"
      [class.primary]="type === 'primary'"
      [class.secondary]="type === 'secondary'"
      [class.ghost]="type === 'ghost'"
      [class.link]="type === 'link'"
      [class.danger]="type === 'danger'"
      [class.button--disabled]="disabled"
      [class.compact]="compact"
      [disabled]="disabled"
      [ngStyle]="styles"
      [nzLoading]="loading"
      (click)="btnClick.emit()"
      [type]="btnType"
    >
      <i
        *ngIf="icon"
        nz-icon
        [nzType]="icon"
      ></i>
      <ng-container *ngIf="label; else elseLabel">{{ label }}</ng-container>
      <ng-template #elseLabel>
        <ng-content></ng-content>
      </ng-template>
    </button>
  `,
  styles: [],
  styleUrls: ['button.component.less']
})
/**
 * Button component based on style guide.
 *
 * Possible options are big and small button.
 *
 * Big button is default and contains styles for types 'primary' | 'secondary' | 'ghost' | 'link'.
 * Small button is available by using property {@link ButtonComponent#small}. Supports only types 'primary' | 'link'.
 */
export class ButtonComponent {
  @Input() public label = '';
  @Input() public icon?: string;
  @Input() public styles?: any;
  @Input() public disabled?: boolean;
  @Input() public loading?: boolean;
  @Input() public compact?: boolean;
  @Input() public small?: boolean;
  @Input() public type: 'primary' | 'secondary' | 'ghost' | 'link' | 'danger' = 'primary';
  /**
   * Gets the classification and default behavior of the button.
   */
  @Input() public btnType?: string = 'button';

  @Output() public btnClick = new EventEmitter<void>();

  constructor() {}
}

@NgModule({
  declarations: [ButtonComponent],
  imports: [
    NzButtonModule, //
    NzIconModule,
    CommonModule
  ],
  exports: [ButtonComponent]
})
export class ButtonModule {}
